import { ScrollRestoration } from "react-router-dom";
import { About } from "../components/About/About";
import { Banner } from "../components/Banner/Banner";
import { Footer } from "../components/Footer/Footer";
import { Offers } from "../components/Offers/Offers";
import { Reviews } from "../components/Reviews/Reviews";

export const Main = () => {
  return (
    <main>
      <ScrollRestoration />
      <Banner />
      <About />
      <Offers />
      <Reviews />
      <Footer />
    </main>
  );
};

import { Title } from "../Title/Title";
import "./Reviews.css";
import reviewsImage from "../../assets/images/review.png"
//import preview1 from "../../assets/images/preview1.png";
//import video1 from "../../assets/videos/IMG_4424.MOV";

export const Reviews = () => {
  return (
    <section className="reviews">
      <Title title="Они уже раскрыли себя" />
      <div className="reviews-text content">
        <img src={reviewsImage} alt="" />
      </div>
      {/*<div className="reviews-wrap">
        <div className="reviews-carousel">
          <div className="reviews-carousel-item">
            <video controls preload="none" poster={preview1}>
              <source src={video1} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>*/}
    </section>
  );
};
